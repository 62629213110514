import PocketBase from "pocketbase";
const pb = new PocketBase(process.env.POCKETBASE_ENDPOINT);
import initLogger from "./log";

const logger = initLogger(process.env.LOGTAIL_SOURCE_TOKEN);

export default function init(output) {
  return async (incomingMessage) => {
    switch (incomingMessage.tag) {
      case "LogError":
        logger.error("LogError", incomingMessage.value);
        break;

      case "GetQuestion": {
        let response;

        try {
          response = await pb
            .collection("questions")
            .getOne(incomingMessage.value, {
              fields:
                "id,title,phase,author,description,created,expiresAt,proposalsEndedAt,ratingEndedAt,phaseCodePublic,expand.proposals_via_questionId.id,expand.proposals_via_questionId.title,expand.proposals_via_questionId.description,expand.proposals_via_questionId.isExcluded,expand.proposals_via_questionId.questionId,expand.proposals_via_questionId.created,expand.ratings_via_questionId.proposalId,expand.ratings_via_questionId.author,expand.ratings_via_questionId.points,expand.ratings_via_questionId.created",
              expand: "proposals_via_questionId,ratings_via_questionId",
            });
        } catch (error) {
          if (
            error.status === 404 &&
            error?.response?.message === "The requested resource wasn't found."
          ) {
            output("GoToNotFound");
            break;
          }
          output("ReceivedError", error?.response?.message);
          logger.error("GetQuestion", { id: incomingMessage.value, error });
          break;
        }

        const {
          id,
          title,
          phase,
          author,
          description,
          created,
          proposalsEndedAt,
          ratingEndedAt,
          phaseCodePublic,
          expand,
        } = response;

        const expiresAt = getExpiresAt(created);

        const question = {
          id,
          title,
          phase,
          author,
          description: description || null,
          createdAt: new Date(created).getTime(),
          expiresAt: new Date(expiresAt).getTime(),
          proposalsEndedAt: proposalsEndedAt
            ? new Date(proposalsEndedAt).getTime()
            : null,
          ratingEndedAt: ratingEndedAt
            ? new Date(ratingEndedAt).getTime()
            : null,
          proposals:
            expand?.proposals_via_questionId?.map((proposal) => ({
              id: proposal.id,
              title: proposal.title,
              description: proposal.description || null,
              isExcluded: proposal.isExcluded,
              questionId: proposal.questionId,
              createdAt: new Date(proposal.created).getTime(),
            })) || [],
          ratings:
            expand?.ratings_via_questionId?.map((rating) => ({
              proposalId: rating.proposalId || "DEFAULT",
              author: rating.author,
              points: rating.points,
              createdAt: new Date(rating.created).getTime(),
            })) || [],
          phaseCodePublic: phaseCodePublic || null,
        };

        output("ReceivedQuestion", question);
        break;
      }

      case "PostNewQuestion": {
        const {
          title: submittedTitle,
          author: submittedAuthor,
          description: submittedDescription,
          phaseCode: submittedPhaseCode,
        } = incomingMessage.value;

        let phaseCode;
        let phaseCodePublic;
        if (submittedPhaseCode) {
          phaseCode = await sha256(submittedPhaseCode);
          phaseCodePublic = await sha256(phaseCode);
        }

        const data = {
          title: submittedTitle,
          author: submittedAuthor,
          description: submittedDescription,
          phase: "PROPOSAL",
          ...(phaseCodePublic && { phaseCodePublic }),
        };

        let record;
        try {
          record = await pb.collection("questions").create(data);

          if (phaseCode) {
            await pb
              .collection("phasecodes")
              .create({ phasecode: phaseCode, questionId: record.id });
          }
        } catch (error) {
          output("ReceivedError", error.message);
          logger.error("PostNewQuestion", { error });
          break;
        }

        const expiresAt = getExpiresAt(record.created);

        const newQuestion = {
          id: record.id,
          title: record.title,
          author: record.author,
          description: record.description || null,
          phase: record.phase,
          createdAt: new Date(record.created).getTime(),
          expiresAt: new Date(expiresAt).getTime(),
          proposals: [],
          ratings: [],
          phaseCodePublic: record.phaseCodePublic || null,
        };

        output("ReceivedQuestion", newQuestion);
        break;
      }

      case "PostNewProposal": {
        const {
          value: {
            questionId: submittedQuestionId,
            title: submittedTitle,
            description: submittedDescription,
          },
        } = incomingMessage;

        const data = {
          questionId: submittedQuestionId,
          title: submittedTitle,
          description: submittedDescription,
        };

        let record;
        try {
          record = await pb.collection("proposals").create(data);
        } catch (error) {
          output("ReceivedError", error.message);
          logger.error("PostNewProposal", { id: submittedQuestionId, error });
          break;
        }

        const { id, title, description, isExcluded, created } = record;

        const newProposal = {
          id,
          title,
          isExcluded,
          ...(description && { description }),
          createdAt: new Date(created).getTime(),
        };

        output("ReceivedPublishedProposal", newProposal);
        break;
      }

      case "ExcludeProposal": {
        const { proposalId, isExcluded } = incomingMessage.value;

        await pb.collection("proposals").update(proposalId, { isExcluded });
        break;
      }

      case "GoToRatingPhase": {
        const { questionId, phaseCode } = incomingMessage.value;

        let record;
        try {
          record = await pb.collection("questions").update(
            questionId,
            {
              phase: "RATING",
              proposalsEndedAt: new Date(),
            },
            {
              fields:
                "id,title,phase,author,description,created,expiresAt,proposalsEndedAt,ratingEndedAt,phaseCodePublic,expand.proposals_via_questionId.id,expand.proposals_via_questionId.title,expand.proposals_via_questionId.description,expand.proposals_via_questionId.isExcluded,expand.proposals_via_questionId.questionId,expand.proposals_via_questionId.created,expand.ratings_via_questionId.proposalId,expand.ratings_via_questionId.author,expand.ratings_via_questionId.points,expand.ratings_via_questionId.created",
              expand: "proposals_via_questionId,ratings_via_questionId",
              headers: {
                "X-Phase-Code": (await sha256(phaseCode)) || "",
              },
            },
          );
        } catch (error) {
          output("ReceivedError", error.message);
          logger.error("GoToRatingPhase", { id: questionId, error });
          break;
        }

        const expiresAt = getExpiresAt(record.created);

        const updatedQuestion = {
          id: record.id,
          title: record.title,
          author: record.author,
          description: record.description || null,
          phase: record.phase,
          createdAt: new Date(record.created).getTime(),
          expiresAt: new Date(expiresAt).getTime(),
          proposals: [],
          ratings: [],
          phaseCodePublic: record.phaseCodePublic || null,
          proposalsEndedAt: record.proposalsEndedAt
            ? new Date(record.proposalsEndedAt).getTime()
            : null,
          ratingEndedAt: record.ratingEndedAt
            ? new Date(record.ratingEndedAt).getTime()
            : null,
          proposals:
            record.expand?.proposals_via_questionId?.map((proposal) => ({
              ...proposal,
              description: proposal.description || null,
              createdAt: new Date(proposal.created).getTime(),
            })) || [],
          ratings:
            record.expand?.ratings_via_questionId?.map((rating) => ({
              ...rating,
              proposalId: rating.proposalId ?? "DEFAULT",
              createdAt: new Date(rating.created).getTime(),
            })) || [],
        };

        output("ReceivedQuestion", updatedQuestion);
        break;
      }

      case "PostRatings": {
        const { questionId, author, ratings } = incomingMessage.value;

        const batchRatings = pb.createBatch();

        for (const [proposalId, points] of ratings) {
          const data = {
            author,
            points,
            proposalId,
            questionId,
          };

          batchRatings.collection("ratings").create(data);
        }

        let result;
        try {
          result = await batchRatings.send();
        } catch (error) {
          output("ReceivedError", error.message);
          logger.error("PostNewRatings", { id: questionId, error });
        }

        const publishedRating = result.map((rating) => ({
          ...rating.body,
          proposalId: rating.body.proposalId ?? "DEFAULT",
          createdAt: new Date(rating.body.created).getTime(),
        }));

        output("ReceivedPublishedRating", publishedRating);
        break;
      }

      case "GoToResultPhase": {
        const { questionId, phaseCode } = incomingMessage.value;

        let record;
        try {
          record = await pb.collection("questions").update(
            questionId,
            {
              phase: "RESULT",
              ratingEndedAt: new Date(),
            },
            {
              fields:
                "id,title,phase,author,description,created,expiresAt,proposalsEndedAt,ratingEndedAt,phaseCodePublic,expand.proposals_via_questionId.id,expand.proposals_via_questionId.title,expand.proposals_via_questionId.description,expand.proposals_via_questionId.isExcluded,expand.proposals_via_questionId.questionId,expand.proposals_via_questionId.created,expand.ratings_via_questionId.proposalId,expand.ratings_via_questionId.author,expand.ratings_via_questionId.points,expand.ratings_via_questionId.created",
              expand: "proposals_via_questionId,ratings_via_questionId",
              headers: {
                "X-Phase-Code": (await sha256(phaseCode)) || "",
              },
            },
          );
        } catch (error) {
          output("ReceivedError", error.message);
          logger.error("GoToResultPhase", { id: questionId, error });
          break;
        }

        const expiresAt = getExpiresAt(record.created);

        const updatedQuestion = {
          id: record.id,
          title: record.title,
          author: record.author,
          description: record.description || null,
          phase: record.phase,
          createdAt: new Date(record.created).getTime(),
          expiresAt: new Date(expiresAt).getTime(),
          proposals: [],
          ratings: [],
          phaseCodePublic: record.phaseCodePublic || null,
          proposalsEndedAt: record.proposalsEndedAt
            ? new Date(record.proposalsEndedAt).getTime()
            : null,
          ratingEndedAt: record.ratingEndedAt
            ? new Date(record.ratingEndedAt).getTime()
            : null,
          proposals:
            record.expand?.proposals_via_questionId?.map((proposal) => ({
              ...proposal,
              description: proposal.description || null,
              createdAt: new Date(proposal.created).getTime(),
            })) || [],
          ratings:
            record.expand?.ratings_via_questionId?.map((rating) => ({
              ...rating,
              proposalId: rating.proposalId ?? "DEFAULT",
              createdAt: new Date(rating.created).getTime(),
            })) || [],
        };

        output("ReceivedQuestion", updatedQuestion);
        break;
      }

      case "DeleteQuestion": {
        const { questionId, phaseCode } = incomingMessage.value;

        await pb.collection("questions").delete(questionId, {
          headers: {
            "X-Phase-Code": (await sha256(phaseCode)) || "",
          },
        });
        break;
      }

      case "SubscribeToPhaseChanges": {
        const questionId = incomingMessage.value;

        await pb.collection("questions").subscribe(
          questionId,
          async ({ record, action }) => {
            if (action === "delete") {
              output("QuestionDeleted");
            } else {
              const sanitizedData = {
                proposalsEndedAt: record.proposalsEndedAt
                  ? new Date(record.proposalsEndedAt).getTime()
                  : null,
                ratingEndedAt: record.ratingEndedAt
                  ? new Date(record.ratingEndedAt).getTime()
                  : null,
              };
              output("ReceivedPhaseChange", sanitizedData);
            }
          },
          {
            fields:
              "proposalsEndedAt, ratingEndedAt, expand.proposals_via_questionId.id, expand.proposals_via_questionId.title, expand.proposals_via_questionId.description, expand.proposals_via_questionId.isExcluded, expand.proposals_via_questionId.questionId, expand.proposals_via_questionId.created, expand.ratings_via_questionId.proposalId, expand.ratings_via_questionId.author, expand.ratings_via_questionId.points, expand.ratings_via_questionId.created",
            expand: "proposals_via_questionId,ratings_via_questionId",
          },
        );
        break;
      }

      case "SubscribeToNewProposals": {
        const questionId = incomingMessage.value;

        await pb.collection("proposals").subscribe(
          "*",
          async ({ record }) => {
            try {
              const proposals = await pb
                .collection("questions")
                .getOne(record.questionId, {
                  fields:
                    "expand.proposals_via_questionId.id,expand.proposals_via_questionId.title,expand.proposals_via_questionId.description,expand.proposals_via_questionId.isExcluded,expand.proposals_via_questionId.questionId,expand.proposals_via_questionId.created",
                  expand: "proposals_via_questionId",
                });
              const updatedProposals =
                proposals.expand.proposals_via_questionId.map((proposal) => ({
                  ...proposal,
                  description: proposal.description || null,
                  createdAt: new Date(proposal.created).getTime(),
                }));
              output("ReceivedNewProposals", updatedProposals);
            } catch (error) {
              if (error.message.includes("autocancelled")) {
                return;
              }
              console.error(error);
            }
          },
          {
            filter: `questionId="${questionId}"`,
          },
        );
        break;
      }

      case "SubscribeToNewRatings": {
        const questionId = incomingMessage.value;

        await pb.collection("ratings").subscribe(
          "*",
          async ({ record }) => {
            try {
              const ratings = await pb
                .collection("questions")
                .getOne(record.questionId, {
                  fields:
                    "expand.ratings_via_questionId.proposalId,expand.ratings_via_questionId.author,expand.ratings_via_questionId.points,expand.ratings_via_questionId.created",
                  expand: "ratings_via_questionId",
                });
              const updatedRatings = ratings.expand.ratings_via_questionId.map(
                (rating) => ({
                  ...rating,
                  proposalId: rating.proposalId ?? "DEFAULT",
                  createdAt: new Date(rating.created).getTime(),
                }),
              );
              output("ReceivedNewRatings", updatedRatings);
            } catch (error) {
              if (error.message.includes("autocancelled")) {
                return;
              }
              console.error(error);
            }
          },
          { filter: `questionId="${questionId}"` },
        );
        break;
      }

      case "UnsubscribeFromPhaseChanges": {
        pb.collection("questions").unsubscribe(incomingMessage.value);
        break;
      }

      case "UnsubscribeFromNewProposals": {
        pb.collection("proposals").unsubscribe("*");
        break;
      }

      case "UnsubscribeFromNewRatings": {
        pb.collection("ratings").unsubscribe("*");
        break;
      }

      case "HashPhaseCode": {
        const text = incomingMessage.value;
        const hash = await sha256(await sha256(text));

        output("ReceivedHashedPhaseCode", hash);
        break;
      }

      case "HashDeleteCode": {
        const text = incomingMessage.value;
        const hash = await sha256(await sha256(text));

        output("ReceivedHashedDeleteCode", hash);
        break;
      }

      default:
        logger.error("JS received unkown message from Elm", incomingMessage);
    }
  };
}

async function sha256(text) {
  if (typeof text !== "string") {
    return null;
  }
  try {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  } catch (error) {
    return null;
  }
}

function getExpiresAt(created) {
  const expiresAt = new Date(created);
  expiresAt.setDate(expiresAt.getDate() + 43);
  expiresAt.setUTCHours(0, 0, 0);
  return expiresAt;
}
